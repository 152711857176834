<template>
  <div id="app">
    <img
      alt="Playlister logo"
      src="./assets/playtrackr-logo.svg"
      height="200"
      width="200"
    />
    <QRGenerator />
  </div>
</template>

<script>
import QRGenerator from "./components/QRGenerator.vue";

export default {
  name: "App",
  components: {
    QRGenerator,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
