<template>
  <div class="url'reference">
    <div class="input-label">Insert the link to generate the QR:</div>
    <input class="input" id="qr-url" v-model="value" />
    <qr-code class="qr-container" id="qr-container" :text="value"></qr-code>

    <!-- <button
      class=""
      v-show="showButton"
      v-on:click="
        () => {
          displayMessages();
        }
      "
    >
      Convert to sound
    </button> -->

    <form
      name="f1"
      action="https://nsspot.herokuapp.com/imagetoaudio/"
      id="f1"
      style="display: none; justify-content: center"
      onsubmit="return false"
    >
      <table style="white-space: nowrap">
        <tbody>
          <tr>
            <td>
              <table style="margin-top: 4px">
                <tbody>
                  <tr>
                    <td>
                      Spectrogram Audio Player
                      <select name="o_playercolor" spellcheck="false">
                        <option value="grayscale">Grayscale</option>
                        <option value="color">Color</option>
                      </select>
                    </td>
                    <td>
                      &nbsp;<button
                        id="btn_openplayer"
                        title="Open Spectrogram Audio Player"
                      >
                        Open Player
                      </button>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      <div
                        id="msg2"
                        style="
                          white-space: nowrap;
                          overflow: hidden;
                          border: 0px solid red;
                        "
                      >
                        (516.8 kB) imagetoaudio.wav
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </form>

    <table style="white-space: nowrap; display: none">
      <tbody>
        <tr>
          <td>
            Length
            <input
              type="number"
              name="o_length"
              class="input1"
              min="1"
              value="6"
              spellcheck="false"
            />
            (seconds, Spectrogram Width, Default 6) &nbsp;Frequency
            <input
              type="number"
              name="o_freq"
              class="input1"
              min="1"
              value="20000"
              spellcheck="false"
            />
            (Hz, Spectrogram Height, Default 20000)
          </td>
        </tr>
        <tr>
          <td>
            Density
            <input
              type="number"
              name="o_factor"
              class="input1"
              min="1"
              value="4"
              spellcheck="false"
            />
            (Default 4, Small numbers make image pixel narrower and sharpen.
            But, processing takes a long time.)
          </td>
        </tr>
        <tr>
          <td>
            <button
              id="btn_scan2"
              type="button"
              onclick="proc_capture(true)"
              class="big1"
              disabled=""
            >
              Create from full image
            </button>
            <button
              id="btn_scan1"
              type="button"
              onclick="proc_capture()"
              class="big1"
              disabled=""
            >
              Create from selected area
            </button>
            &nbsp;<span id="msg1"></span>
          </td>
        </tr>
        <tr>
          <td>
            <table style="margin-top: 4px">
              <tbody>
                <tr>
                  <td>
                    Spectrogram Audio Player
                    <select name="o_playercolor" spellcheck="false">
                      <option value="grayscale">Grayscale</option>
                      <option value="color">Color</option>
                    </select>
                  </td>
                  <td>
                    &nbsp;<button
                      id="btn_openplayer"
                      title="Open Spectrogram Audio Player"
                    >
                      Open Player
                    </button>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <div
                      id="msg2"
                      style="
                        width: 500px;
                        white-space: nowrap;
                        overflow: hidden;
                        border: 0px solid red;
                      "
                    >
                      (516.8 kB) imagetoaudio.wav
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="content" v-show="displayContent">
      <div id="mensaje1" class="actions-messages"></div>

      <table id="player1" style="display: none; justify-content: center">
        <tbody>
          <tr>
            <td>
              <table style="display: none">
                <tbody>
                  <tr>
                    <td>
                      <button id="btn_play" onclick="proc_play()">Play</button>
                      <button id="btn_stop" onclick="proc_stop()">Stop</button>
                    </td>
                    <td>Volume</td>
                    <td>
                      <input
                        id="volume1"
                        type="range"
                        min="0"
                        max="100"
                        step="3"
                        value="80"
                        onchange="volume1_onchange()"
                        style="width: 120px; padding: 0px"
                        title="Audio Volume"
                      />
                    </td>
                    <td>Size</td>
                    <td>
                      <input
                        type="range"
                        id="size_width"
                        min="400"
                        max="1200"
                        step="1"
                        value="750"
                        style="width: 120px; padding: 0px"
                        onchange="size_width_onchange()"
                        title="Change the size"
                      />
                    </td>
                    <td>
                      <button id="btn_fullscreen" title="Full Screen On/Off">
                        Full Screen
                      </button>
                    </td>
                    <td></td>
                    <td>
                      <button id="btn_close" title="Close Player">
                        Close Player
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <canvas
                id="sv_canvas"
                width="900"
                height="512"
                style="
                  width: 750px;
                  height: 426px;
                  margin-bottom: 0px;
                  border: 1px solid black;
                  background-color: black;
                "
              ></canvas>
            </td>
          </tr>
          <tr>
            <td>
              <div id="audio1_div">
                <audio
                  autoplay="autoplay"
                  controls="controls"
                  id="audio1"
                  style="width: 750px"
                  crossorigin="anonymous"
                ></audio>
              </div>
              <div
                id="msg3"
                style="
                  white-space: nowrap;
                  overflow: hidden;
                  border: 0px solid red;
                  color: green;
                  width: 750px;
                "
              >
                &nbsp;Paused
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import WAudioplayerVue from "w-audioplayer-vue";

Vue.component("w-audioplayer-vue", WAudioplayerVue);

export default {
  name: "QRGenerator",
  data() {
    return {
      value: "",
      sound: null,
      showButton: false,
      displayContent: false,
    };
  },
  watch: {
    // whenever question changes, this function will run
    value: function () {
      this.displayMessages();
    },
  },
  methods: {
    convertToSound() {
      axios
        .post("http://localhost:8081/convert")
        .then((response) => {
          this.sound = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    displayMessages() {
      this.displayContent = false;
      console.log("a");
      setTimeout(() => {
        this.displayContent = true;
        console.log("b");
        this.showButton = true;
      }, 1000);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
