import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import VueQRCodeComponent from 'vue-qr-generator'

Vue.component('qr-code', VueQRCodeComponent)

import "./assets/css/styles.css";

new Vue({
  render: h => h(App),
}).$mount('#app')
